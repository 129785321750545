<template>
    <div>
        <div v-if="this.$store.state.isLoggedIn">
            You are logged in!
            <div class="buttons">
                <button v-on:click="logout" class="button">Log out</button>
            </div> 
        </div>
        <div v-if="!this.$store.state.isLoggedIn">
            Register now!
            <div class="buttons">
                <button v-on:click="login" class="button">Register</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        login: function () {
            this.$store.commit("login");
        },
        logout: function () {
            this.$store.commit("logout");
        }
    }
    
}
</script>

<style scoped>

</style>